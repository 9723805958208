import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { DisplayedComponent } from '../../auth/components/login/login.component';

export type ComponentType = 'login' | 'forgot-password' | 'reset-password' | 'signup' | '';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _snackBar = inject(MatSnackBar);

  // isLoading observable
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  setLoading(isLoading: boolean): void {
    this.isLoadingSubject.next(isLoading);
  }

  constructor() {}

  public SubmitForm = new BehaviorSubject<DisplayedComponent | null>(null);
  submitForm = this.SubmitForm.asObservable();

  openSnackBar(message: string, duration: number = 3000) {
    this._snackBar.open(message, '', { duration: duration, panelClass: ['snackbar'] });
  }

  dismissSnackBar() {
    this._snackBar.dismiss();
  }

  changeSubmitForm(changeTo: DisplayedComponent | any) {
    this.SubmitForm.next(changeTo);
  }

  getSubmitFormValue(): DisplayedComponent | null {
    return this.SubmitForm.getValue();
  }
}
