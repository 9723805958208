import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, map, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUser } from '../../auth/user.model'; // Ensure this is the correct import for the User class
import { Platform } from '@ionic/angular';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserAccount } from '../../models/user.model';
import { Router } from '@angular/router';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentAuthUserSubject: BehaviorSubject<AuthUser>;
  public currentAuthUser: Observable<AuthUser>;

  public get currentAuthUserValue(): AuthUser {
    return this.currentAuthUserSubject.value;
  }

  user = new BehaviorSubject<AuthUser | null>(null);
  authUser: Observable<AuthUser | null> = this.user.asObservable();

  jwt: string;

  isLoggedIn: boolean = false;

  userDetails = new BehaviorSubject<any>(null);

  signupObj: AuthUser = new AuthUser('', '', 0, false, {} as UserAccount);

  // redirect url after login
  redirectUrl: string | null = null;

  setRedirectUrl(redirectUrl: string): void {
    if (!this.redirectUrl) {
      this.redirectUrl = redirectUrl;
    }
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }

  // when user logs in and hits guard, save the url they were trying to access for redirect later
  private url = new BehaviorSubject('');
  UrlBeforeLogin = this.url.asObservable();

  saveUrlBeforeLogin(url: any) {
    this.url.next(url);
  }

  constructor(
    public platform: Platform,
    private http: HttpClient,
    private router: Router,
    private notificationsService: NotificationsService
  ) {
    this.platform = platform;
  }

  setLoggedIn(value: boolean) {
    this.isLoggedIn = value;
  }

  isAuthenticated(): true | false {
    return this.isLoggedIn;
  }

  signUp(email: string, password: string, firstName: string, lastName: string): Observable<{ email: string }> {
    const url = `${environment.naybrsAwsApi}/store/preverification/owner/public/account`;
    const body = { email: email, password: password, first_name: firstName, family_name: lastName };
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<{ email: string }>(url, body, { headers });
  }

  async autoLogin() {
    const authDataString: string | null = this.getCookie('Naybrs_authData');

    const userData: AuthUser = authDataString ? JSON.parse(authDataString) : null;

    if (!userData) {
      return;
    }

    const loadedUser = new AuthUser(
      userData.jwt,
      userData.jwt_refresh,
      userData.jwt_expires_at,
      userData.verified,
      userData.user
    );

    if (loadedUser.jwt) {
      console.log('inside block autoLogin', loadedUser);
      this.user.next(loadedUser);
      console.log('user changed constructor');
      console.log(this.redirectUrl, 'redirect url');

      if (this.redirectUrl) {
        console.log('made it into redirectURL');
        this.router.navigate([this.redirectUrl]);
      }
    }
  }

  getUserAccount(): Observable<UserAccount> {
    const url = `${environment.naybrsAwsApi}/store/preverification/owner/secure/account`;
    let headers;

    try {
      const storedUser: string | null = localStorage.getItem('authData');
      const jwt = storedUser ? JSON.parse(storedUser).jwt : null;

      if (jwt) {
        headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` };
      } else {
        console.error('JWT is not available');
        // Redirect to login page
        this.router.navigate(['/auth/login']);
        return EMPTY;
      }
    } catch (error) {
      console.error('Failed to access localStorage', error);
      // Redirect to login page
      this.router.navigate(['/auth/login']);
      return EMPTY;
    }

    console.log('getuser headers', headers);
    return this.http.get<UserAccount>(url, { headers });
  }

  signUpConfirmEmail(email: string, confirmationCode: string): Observable<any> {
    const url = `${environment.naybrsAwsApi}/store/preverification/owner/public/account/confirm-email`;
    const body = { email: email, confirmation_code: confirmationCode };
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<{ email: string }>(url, body, { headers });
  }

  login(un: string, pw: string): Observable<AuthUser> {
    const url = `${environment.naybrsAwsApi}/store/owner/public/jwt`;
    const body = { username: un, password: pw };
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<AuthUser>(url, body, { headers });
  }

  async logout() {
    localStorage.removeItem('authData');
    this.router.navigate(['/auth/login']);
  }

  requestPasswordResetEmail(email: string): Observable<boolean> {
    const url = `${environment.naybrsAwsApi}/user/forgot-password/request`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = { email };
    return this.http.post(url, body, { headers }).pipe(
      map((resp: any) => {
        console.log('requestPasswordResetEmail resp', resp);

        if (resp && resp.message) {
          throw new Error(resp.data.message);
        }
        return resp.requestPasswordResetEmail;
      }),
      catchError((error) => {
        return throwError(() => new Error(error.message || 'An error occurred while requesting password reset email.'));
      })
    );
  }

  checkEmailAvailable(email: string): Observable<boolean> {
    const url = `${environment.naybrsAwsApi}/store/owner/public/email-availability/${encodeURIComponent(email)}`;
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<{ isEmailAvailable?: boolean }>(url, { headers }).pipe(
      switchMap((resp) => {
        if (resp.isEmailAvailable === undefined) {
          return throwError(() => new Error('isEmailAvailable property does not exist'));
        }
        return of(resp.isEmailAvailable);
      })
    );
  }

  resendEmailConfirmation(email: string): Observable<boolean> {
    const url = `${environment.naybrsAwsApi}/store/preverification/owner/public/account/resend-confirmation`;
    const body: { email: string } = { email };
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<{ resendConfirmation?: boolean }>(url, body, { headers }).pipe(
      switchMap((resp) => {
        if (resp.resendConfirmation === undefined) {
          return throwError(() => new Error('email property does not exist'));
        }
        return of(resp.resendConfirmation);
      })
    );
  }

  confirmResetPassword(
    email: string,
    newPassword: string,
    confirmationCode: string,
    clientId: string
  ): Observable<boolean> {
    const url = `${environment.naybrsAwsApi}/user/forgot-password/confirm`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = { email, newPassword, confirmationCode, clientId };
    return this.http.post(url, body, { headers }).pipe(
      map((resp: any) => {
        console.log('confirmResetPassword resp', resp);

        if (resp && resp.message) {
          throw new Error(resp.message);
        }
        return resp.passwordChangeConfirmed;
      }),
      catchError((error: HttpErrorResponse) => {
        this.notificationsService.openSnackBar('Something went wrong. Please try again.');
        return throwError(() => new Error(error.message || 'An error occurred while confirming password reset.'));
      })
    );
  }

  setUserData(tokens: AuthUser | null) {
    if (!tokens) {
      this.user.next(null);
      return;
    }

    const authUser = new AuthUser(tokens.jwt, tokens.jwt_refresh, tokens.jwt_expires_at, tokens.verified, tokens.user);

    this.user.next(authUser);
    this.storeAuthData(authUser);
  }

  private async storeAuthData(authUser: AuthUser) {
    console.log('AuthObj from storedata', authUser);
    this.setCookie('Naybrs_authData', JSON.stringify(authUser), authUser.jwt_expires_at);
    this.setCookie('Naybrs_jwt_cognito', authUser.jwt, authUser.jwt_expires_at);
    this.setCookie('Naybrs_RefreshToken', authUser.jwt_refresh, 2592000);

    // Log the cookies to verify they are set correctly
    console.log('Cookies after setting:');
    console.log(document.cookie);
  }

  postUserVerifyAccountCreationAndAutologin(clientId: string, username: string): Observable<any> {
    const url: string = `${environment.naybrsAwsApi}/store/owner/public/verify/post-verify/autologin`;
    const body: {
      clientId: string;
      username: string;
    } = {
      clientId,
      username,
    };
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, body, { headers });
  }

  setCookie(name: string, value: string, expiryTime: number) {
    if (!name || !value) {
      console.log('Cookie name or value is missing');
      return;
    }

    let expires = '';
    if (expiryTime) {
      const date = new Date();
      date.setTime(date.getTime() + expiryTime * 1000); // expiryTime in seconds
      expires = '; expires=' + date.toUTCString();
    }

    const isSecure = location.protocol === 'https:';

    // Determine the appropriate domain for cross-subdomain cookies
    let domain = '';
    const hostname = window.location.hostname;

    if (hostname !== 'localhost') {
      if (hostname.includes('demo.store.naybrs.com')) {
        // Set cookie to work for both auth.demo.naybrs.com and demo.naybrs.com
        domain = '.demo.naybrs.com';
      } else if (hostname.includes('staging.store.naybrs.com')) {
        // Set cookie to work for both auth.staging.naybrs.com and staging.naybrs.com
        domain = '.staging.naybrs.com';
      } else if (hostname.includes('store.naybrs.com')) {
        // Set cookie to work for both auth.naybrs.com and naybrs.com
        domain = '.naybrs.com';
      }
    }

    let cookieString = `${name}=${value || ''}${expires}; path=/; SameSite=Lax`;
    if (domain) {
      cookieString += `; domain=${domain}`;
    }
    if (isSecure) {
      cookieString += '; Secure';
    }

    document.cookie = cookieString;
    console.log('Cookie Set:', cookieString);
  }

  getCookie(name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) {
        const cookieValue = c.substring(nameEQ.length);
        try {
          return JSON.parse(cookieValue);
        } catch (e) {
          return cookieValue;
        }
      }
    }
    return null;
  }
}
