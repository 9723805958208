interface EnvironmentConfig {
  naybrsMagentoApi: string;
  naybrsAwsApi: string;
  development?: boolean;
  production?: boolean;
  staging?: boolean;
  demo: true;
  googleClientId: string;
  cognitoDomain: string;
  cognitoClientId: string;
  naybrsAuthUrl: string;
  naybrsStoreUrl: string;
}

export const environment: EnvironmentConfig = {
  demo: true,
  naybrsMagentoApi: 'https://demo-api.naybrs.com',
  naybrsAwsApi: 'https://bcf4r8e22h.execute-api.us-west-1.amazonaws.com/demo',
  cognitoDomain: 'https://development-naybrs-store.auth.us-west-2.amazoncognito.com',
  cognitoClientId: '40majiffuq0jgvi20pjhq0sqm4',
  naybrsAuthUrl: 'https://auth.demo.naybrs.com',
  naybrsStoreUrl: 'https://demo.naybrs.com',
  googleClientId: '39246516600-1h4r5ms0hrba3vo2lle8bh87j2p20ptd.apps.googleusercontent.com',
};
