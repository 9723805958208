import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  private isMobileSubject = new BehaviorSubject<boolean>(false);
  private isTabletSubject = new BehaviorSubject<boolean>(false);
  private isDesktopSubject = new BehaviorSubject<boolean>(false);

  isMobile$: Observable<boolean> = this.isMobileSubject.asObservable();
  isTablet$: Observable<boolean> = this.isTabletSubject.asObservable();
  isDesktop$: Observable<boolean> = this.isDesktopSubject.asObservable();

  devices$: Observable<{ isMobile: boolean; isTablet: boolean; isDesktop: boolean }>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([
        '(max-width: 599.98px) and (orientation: portrait)',
        '(max-width: 959.98px) and (orientation: landscape)',
        '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)',
        '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
        '(min-width: 840px) and (orientation: portrait)',
        '(min-width: 1280px) and (orientation: landscape)',
      ])
      .subscribe((result: BreakpointState) => {
        const breakpoints = result.breakpoints;
        this.isMobileSubject.next(
          breakpoints['(max-width: 599.98px) and (orientation: portrait)'] ||
            breakpoints['(max-width: 959.98px) and (orientation: landscape)']
        );
        this.isTabletSubject.next(
          breakpoints['(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)'] ||
            breakpoints['(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)']
        );
        this.isDesktopSubject.next(
          breakpoints['(min-width: 840px) and (orientation: portrait)'] ||
            breakpoints['(min-width: 1280px) and (orientation: landscape)']
        );
      });

    this.devices$ = combineLatest([this.isMobile$, this.isTablet$, this.isDesktop$]).pipe(
      map(([isMobile, isTablet, isDesktop]) => ({ isMobile, isTablet, isDesktop }))
    );
  }
}
